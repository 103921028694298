/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
// ---------------------------------------------------------

export const PRIVATE_TOKEN_CREATE_SUPER_ADMIN =
  process.env.REACT_APP_PRIVATE_TOKEN_CREATE_SUPER_ADMIN;

export const TOKEN_DB = process.env.REACT_APP_TOKEN_DB;

export const API_BASE = process.env.REACT_APP_ROUTE_DATABASE;

export const API_TEST = process.env.REACT_APP_TEST_ROUTE_DATABASE;

export const PATH_REGISTER_APPLICANT =
  process.env.REACT_APP_PATH_REGISTER_APPLICANT;

export const PATH_REGISTER_COMPANY =
  process.env.REACT_APP_PATH_REGISTER_COMPANY;

export const PATH_UPDATE_APPLICANT =
  process.env.REACT_APP_PATH_UPDATE_APPLICANT;

export const PATH_UPDATE_IMAGE_APPLICANT =
  process.env.REACT_APP_PATH_UPDATE_IMAGE_APPLICANT;

export const PATH_REGISTER_INFLUENCER =
  process.env.REACT_APP_PATH_REGISTER_INFLUENCER;

export const PATH_GET_PREVIEW_APPLICANTS =
  process.env.REACT_APP_PATH_GET_PREVIEW_APPLICANTS;

export const PATH_LOGIN_APPLICANT = process.env.REACT_APP_PATH_LOGIN_APPLICANT;

export const PATH_LOGIN_COMPANY = process.env.REACT_APP_PATH_LOGIN_COMPANY;

export const PATH_LOGIN_INFLUENCER =
  process.env.REACT_APP_PATH_LOGIN_INFLUENCER;

export const PATH_ADD_OFFER = process.env.REACT_APP_PATH_ADD_OFFER;

export const PATH_OFFERS = process.env.REACT_APP_PATH_OFFERS;

export const PATH_ACTIVITIES = process.env.REACT_APP_PATH_ACTIVITIES;
export const PATH_GET_ALL_ACTIVITIES =
  process.env.REACT_APP_PATH_GET_ALL_ACTIVITIES;

export const PATH_GET_APPLICANT_BY_ID =
  process.env.REACT_APP_PATH_GET_APPLICANT_BY_ID;

export const PATH_GET_PACKAGES = process.env.REACT_APP_PATH_GET_PACKAGES;

export const PATH_BUY_PACKAGE = process.env.REACT_APP_PATH_BUY_PACKAGE;

export const PATH_SUBSCRITION = process.env.REACT_APP_PATH_SUBSCRITION;

export const PATH_FILTER_APPLICANTS =
  process.env.REACT_APP_PATH_FILTER_APPLICANTS;

export const PATH_UNLOCK = process.env.REACT_APP_PATH_UNLOCK;

export const PATH_APPLICANT_UNLOCK =
  process.env.REACT_APP_PATH_APPLICANT_UNLOCK;

export const PATH_INFLUENCER_GET_TABLE =
  process.env.REACT_APP_PATH_INFLUENCER_GET_TABLE;

export const PATH_SUPERADMIN_LOGIN =
  process.env.REACT_APP_PATH_SUPERADMIN_LOGIN;

export const PATH_SUPERADMIN_REGISTER =
  process.env.REACT_APP_PATH_SUPERADMIN_REGISTER;
