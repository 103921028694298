/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Link } from "react-router-dom";
// ---------------------------------------------------------
import "./Activity.css";
import assets from "../../assets/assets";
import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// ---------------------------------------------------------

const Activity = ({
  info = {
    place: "Loading...",
    mail: "Loading...",
    phone: "Loading...",
    description: "Loading...",
    title: "Loading...",
    imgs: [],
  },
  isScreen = false,
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const { place, mail, phone, description, title } = info;
  let imgs = info.imgs;

  const formatedSubpathWork = () => {
    let subpath = `/activity?place=${place}&mail=${mail}&phone=${phone}&description=${description}&title=${title}&imgs=${JSON.stringify(
      imgs
    )}`;
    return subpath;
  };

  let subpath = formatedSubpathWork(info);

  const IsScreenOrLinking = ({ screen, children }) => {
    if (screen) {
      return <section className="work_container">{children}</section>;
    } else {
      return (
        <Link className="work_container" to={subpath} target="_blank">
          {children}
        </Link>
      );
    }
  };

  return (
    <>
      {/* Class names starting with "work" refer to the file: Work.css */}

      <IsScreenOrLinking screen={isScreen}>
        <div className="work_title_container">
          <h2 className="work_title">{title}</h2>
        </div>

        <div className="work_item_container">
          <div className="work_item_icon_container">
            <img
              className="work_item_icon"
              src={assets.icons.iconLocation212121}
              alt={`Icon Company ${place}`}
            />
          </div>

          <p className="work_item_txt">{place}</p>
        </div>

        <div className="">
          {isScreen ? (
            <>
              {imgs.length ? (
                <button
                  onClick={() => setFullScreen(true)}
                  className="act_btn_fullscreen_open"
                >
                  <img
                    className="act_btn_fullscreen_img"
                    src={`https://www.companyu.de/backend/${imgs[0]}`}
                    alt=""
                  />

                  <p className="act_btn_fullscreen_img_see">Sehen</p>
                </button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {imgs.length ? (
                <div className="act_btn_fullscreen_open">
                  <img
                    className="act_btn_fullscreen_img"
                    src={`https://www.companyu.de/backend/${imgs[0]}`}
                    alt=""
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>

        {isScreen ? (
          <>
            <div className="work_moreInfo_container">
              <h3>Mehr sehen</h3>

              <section className="work_moreInfo_data_container">
                <div className="work_moreInfo_data">
                  <h5>Beschreibung</h5>

                  <p>{description}</p>
                </div>
              </section>
            </div>
          </>
        ) : (
          <>
            <p className="work_moreInfo_linking">
              Mehr sehen{" "}
              <svg
                width="10"
                height="10"
                viewBox="0 0 99 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_481_6)">
                  <path
                    d="M1.26349 97.0296C2.92365 98.6897 5.65803 98.6897 7.36701 97.0296L69.7205 34.8226L81.6834 46.8831C83.783 48.9827 86.5174 47.8108 87.3475 44.7835L98.2362 4.45146C98.9686 1.66826 96.576 -0.626665 93.8904 0.154585L53.5584 11.0433C50.5799 11.8245 49.408 14.5101 51.5076 16.6097L63.617 28.719L1.26349 90.9261C-0.445489 92.5862 -0.396661 95.3694 1.26349 97.0296Z"
                    fill="black"
                    fill-opacity="0.85"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_481_6">
                    <rect width="98.3766" height="99.9674" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </p>
          </>
        )}

        <div className="work_contact_container">
          <h3>Kontaktieren</h3>

          <nav className="work_contact_nav_container">
            {mail !== "" && (
              <Link
                to={`mailto:${mail}`}
                target="_blank"
                className="work_btn_contact"
              >
                {mail}
              </Link>
            )}

            {phone !== "" && (
              <Link
                to={`tel:${phone}`}
                target="_blank"
                className="work_btn_contact"
              >
                {phone}
              </Link>
            )}
          </nav>
        </div>

        {fullScreen && (
          <div className="act_fullscreen_container">
            <ImageGallery
              renderPlayPauseButton={() => {
                return (
                  <>
                    <button
                      className="act_fullscreen_close_container"
                      onClick={() => setFullScreen(false)}
                    >
                      Cerrar
                    </button>
                  </>
                );
              }}
              items={imgs.map((img) => ({
                original: `https://www.companyu.de/backend/${img}`,
                thumbnail: `https://www.companyu.de/backend/${img}`,
              }))}
              showNav={true}
              showThumbnails={true}
              showFullscreenButton={true}
            />
          </div>
        )}
      </IsScreenOrLinking>
    </>
  );
};

export default Activity;
