/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Link } from "react-router-dom";
// ---------------------------------------------------------
import "./Work.css";
import { t } from "../../languages/settingsLanguages";
import { additionalServices, studiesOptions } from "../../utils/options";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const Work = ({
  work = {
    logo: "",
    company: "Loading...",
    vacancy: "Loading...",
    working: "Loading...",
    qualification: "",
    zip: "",
    city: "Loading...",
    benefit: "",
    phone: "Loading...",
    mail: "Loading...",
    /* -------- More Info -------- */
    selfDescriptionCompany: "Loading...",
    requirements: "Loading...",
    workDescription: "Loading...",
    workPlaces: "Loading...",
    workSchedules: "Loading...",
    salaryRange: "Loading...",
    careerAndDevelopmentOpportunities: "Loading...",
  },
  isScreen = false,
}) => {
  const { company, vacancy, working, zip, city, benefit, phone, mail } = work,
    logo = work?.logo ?? "",
    selfDescriptionCompany = !!work.selfDescriptionCompany.length
      ? work.selfDescriptionCompany
      : "Nicht verfügbar",
    requirements = !!work.requirements.length
      ? work.requirements
      : "Nicht verfügbar",
    workDescription = !!work.workDescription.length
      ? work.workDescription
      : "Nicht verfügbar",
    workPlaces = !!work.workPlaces.length ? work.workPlaces : "Nicht verfügbar",
    workSchedules = !!work.workSchedules.length
      ? work.workSchedules
      : "Nicht verfügbar",
    salaryRange = !!work.salaryRange.length
      ? work.salaryRange
      : "Nicht verfügbar",
    careerAndDevelopmentOpportunities = !!work.careerAndDevelopmentOpportunities
      .length
      ? work.careerAndDevelopmentOpportunities
      : "Nicht verfügbar";
  let qualification = studiesOptions.filter((item1) =>
    [work.qualification].some((item2) => item2 === item1.value)
  );

  const formatedSubpathWork = () => {
    let subpath = `/offer-work?logo=${logo}&company=${company}&vacancy=${vacancy}&working=${working}&qualification=${qualification}&zip=${zip}&city=${city}&benefit=${benefit}&phone=${phone}&mail=${mail}&selfDescriptionCompany=${selfDescriptionCompany}&requirements=${requirements}&workDescription=${workDescription}&workPlaces=${workPlaces}&workSchedules=${workSchedules}&salaryRange=${salaryRange}&careerAndDevelopmentOpportunities=${careerAndDevelopmentOpportunities}`;
    return subpath;
  };

  let subpath = formatedSubpathWork(work);

  const IsScreenOrLinking = ({ screen, children }) => {
    if (screen) {
      return <section className="work_container">{children}</section>;
    } else {
      return (
        <Link className="work_container" to={subpath} target="_blank">
          {children}
        </Link>
      );
    }
  };

  return (
    <>
      {/* Class names starting with "work" refer to the file: Work.css */}

      <IsScreenOrLinking screen={isScreen}>
        <div className="work_title_container">
          <h2 className="work_title">{vacancy}</h2>
        </div>

        <div className="work_company_container">
          <div className="work_company_icon_container">
            <img
              className="work_company_icon"
              src={assets.icons.iconCompanya6a6a6}
              alt={`Icon Company ${company}`}
            />
          </div>

          <div style={{ flex: 1 }}>
            <h3 className="work_company">{company}</h3>
          </div>

          {!!logo.length && (
            <div className="work_company_logo_container">
              <img
                className="work_company_logo"
                src={`https://www.companyu.de/backend/${logo}`}
                alt={`Logo Company ${company}`}
              />
            </div>
          )}
        </div>

        <div className="work_item_container">
          <div className="work_item_icon_container">
            <img
              className="work_item_icon"
              src={assets.icons.iconLocation212121}
              alt={`Icon Company ${city}`}
            />
          </div>

          <p className="work_item_txt">
            {city}
            {zip !== "" && ","} {zip}
          </p>
        </div>

        <div className="work_item_container">
          <div className="work_item_icon_container">
            <img
              className="work_item_icon"
              src={assets.icons.iconTime212121}
              alt={`Icon Company ${working}`}
            />
          </div>

          <p className="work_item_txt">{working}</p>
        </div>

        {!!qualification.length && (
          <div className="work_item_container">
            <div className="work_item_icon_container">
              <img
                className="work_item_icon"
                src={assets.icons.iconQualification212121}
                alt={`Icon Company ${qualification}`}
              />
            </div>

            <p className="work_item_txt">{qualification[0]?.name}</p>
          </div>
        )}

        {benefit?.length >= 1 &&
          (() => {
            let listBenefit = JSON.parse(benefit) ?? [];
            let arr = additionalServices.filter((item1) =>
              listBenefit.some((item2) => item2.value === item1.value)
            );
            return (
              <>
                <div className="work_benefit_container">
                  <h3>Zusatz Leistungen</h3>

                  <section className="work_benefit_list_container">
                    {arr.map((service, index) => {
                      if (!isScreen) {
                        return (
                          index < 3 && (
                            <div
                              key={`benefit_${index}`}
                              className="work_item_container"
                            >
                              <div className="work_item_icon_container">
                                <img
                                  className="work_item_icon"
                                  src={service?.icon}
                                  alt={`Icon Company ${service?.value}`}
                                />
                              </div>

                              <p className="work_item_txt">
                                {t(service?.name)}
                              </p>
                            </div>
                          )
                        );
                      } else {
                        return (
                          <div
                            key={`benefit_${index}`}
                            className="work_item_container"
                          >
                            <div className="work_item_icon_container">
                              <img
                                className="work_item_icon"
                                src={service?.icon}
                                alt={`Icon Company ${service?.value}`}
                              />
                            </div>

                            <p className="work_item_txt">{t(service?.name)}</p>
                          </div>
                        );
                      }
                    })}
                    {!isScreen && arr.length > 3 && <p>...</p>}
                  </section>
                </div>
              </>
            );
          })()}

        {isScreen ? (
          <>
            <div className="work_moreInfo_container">
              <h3>Mehr sehen</h3>

              <section className="work_moreInfo_data_container">
                <div className="work_moreInfo_data">
                  <h5>Jobanforderungen</h5>

                  <p>{requirements}</p>
                </div>
              </section>

              <section className="work_moreInfo_data_container">
                <div className="work_moreInfo_data">
                  <h5>Jobbeschreibung</h5>

                  <p>{workDescription}</p>
                </div>
              </section>

              <section className="work_moreInfo_data_container">
                <div className="work_moreInfo_data">
                  <h5>Arbeitsorte</h5>

                  <p>{workPlaces}</p>
                </div>
              </section>

              <section className="work_moreInfo_data_container">
                <div className="work_moreInfo_data">
                  <h5>Arbeitszeiten</h5>

                  <p>{workSchedules}</p>
                </div>
              </section>

              <section className="work_moreInfo_data_container">
                <div className="work_moreInfo_data">
                  <h5>Gehaltsspanne</h5>

                  <p>{salaryRange}</p>
                </div>
              </section>

              <section className="work_moreInfo_data_container">
                <div className="work_moreInfo_data">
                  <h5>Karriere- und Entwicklungsmöglichkeiten</h5>

                  <p>{careerAndDevelopmentOpportunities}</p>
                </div>
              </section>
            </div>
          </>
        ) : (
          <>
            <p className="work_moreInfo_linking">
              Mehr sehen{" "}
              <svg
                width="10"
                height="10"
                viewBox="0 0 99 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_481_6)">
                  <path
                    d="M1.26349 97.0296C2.92365 98.6897 5.65803 98.6897 7.36701 97.0296L69.7205 34.8226L81.6834 46.8831C83.783 48.9827 86.5174 47.8108 87.3475 44.7835L98.2362 4.45146C98.9686 1.66826 96.576 -0.626665 93.8904 0.154585L53.5584 11.0433C50.5799 11.8245 49.408 14.5101 51.5076 16.6097L63.617 28.719L1.26349 90.9261C-0.445489 92.5862 -0.396661 95.3694 1.26349 97.0296Z"
                    fill="black"
                    fill-opacity="0.85"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_481_6">
                    <rect width="98.3766" height="99.9674" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </p>
          </>
        )}

        <div className="work_contact_container">
          <h3>Kontaktieren</h3>

          <nav className="work_contact_nav_container">
            {mail !== "" && (
              <Link
                to={`mailto:${mail}`}
                target="_blank"
                className="work_btn_contact"
              >
                {mail}
              </Link>
            )}

            {phone !== "" && (
              <Link
                to={`tel:${phone}`}
                target="_blank"
                className="work_btn_contact"
              >
                {phone}
              </Link>
            )}
          </nav>
        </div>
      </IsScreenOrLinking>
    </>
  );
};

export default Work;
