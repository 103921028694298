/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import {
  API_BASE,
  PATH_ACTIVITIES,
  PATH_ADD_OFFER,
  PATH_GET_ALL_ACTIVITIES,
  PATH_OFFERS,
  PATH_SUPERADMIN_LOGIN,
  TOKEN_DB,
} from "../../api/apis";
import {
  setDataArrAllActivities,
  setDataArrAllWorks,
  setUserSuperadmin,
} from "../../context/Actions";
// ---------------------------------------------------------

const servicesSuperAdminLogin = async (dispatch, form, response = () => {}) => {
  const API = API_BASE;

  await fetch(`${API}${PATH_SUPERADMIN_LOGIN}`, {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      "x-token": TOKEN_DB,
      "Content-Type": "application/json",
      // Authorization: `Bearer ${privateSessionToken}`,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      let userSuperAdmin = {
        token: res.token,
        id: res.id,
        name: res.name,
        mail: res.mail,
      };
      setUserSuperadmin(dispatch, userSuperAdmin);
      localStorage.setItem("usersuperadmin", JSON.stringify(userSuperAdmin));
      response("successful");
    })
    .catch((error) => {
      // console.error(error);
      response(
        error.message === "token-expired" ? error.message : "unexpected-error"
      );
    });
};

const servicesSuperAdmin = {
  login: servicesSuperAdminLogin,
  // register: ,
};

export default servicesSuperAdmin;

export const addWorks = async (dispatch, form, privateSessionToken) => {
  let addWork = "";

  const formData = new FormData();

  formData.append("logo", form.logo ?? "");
  formData.append("vacancy", form.vacancy);
  formData.append("company", form.company);
  formData.append("qualification", form.qualification);
  formData.append("working", form.working);
  formData.append("zip", form.zip);
  formData.append("city", form.city);
  formData.append(
    "benefit",
    JSON.stringify(form.benefit) ?? JSON.stringify([])
  );
  formData.append("phone", form.phone);
  formData.append("mail", form.mail);
  /* -------- More Info -------- */
  formData.append("selfDescriptionCompany", form.selfDescriptionCompany);
  formData.append("requirements", form.requirements);
  formData.append("workDescription", form.workDescription);
  formData.append("workPlaces", form.workPlaces);
  formData.append("workSchedules", form.workSchedules);
  formData.append("salaryRange", form.salaryRange);
  formData.append(
    "careerAndDevelopmentOpportunities",
    form.careerAndDevelopmentOpportunities
  );
  formData.append("create_usuario_id", form.id);

  const API = API_BASE;

  addWork = await fetch(`${API}${PATH_ADD_OFFER}`, {
    method: "POST",
    body: formData,
    headers: {
      "x-token": TOKEN_DB,
      Authorization: `Bearer ${privateSessionToken}`,
    },
  })
    .then((res) => {
      return Number(res.status) >= 200 && Number(res.status) <= 299
        ? "successful"
        : "failed";
    })
    .catch((error) => {
      console.error(error?.message);

      return "failed";
    });

  return addWork;
};

export const getAllWorksInSuperAdmin = async (dispatch) => {
  let arrWorks = [];

  const API = API_BASE;

  arrWorks = await fetch(`${API}${PATH_OFFERS}`, {
    method: "GET",
    headers: {
      "x-token": TOKEN_DB,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error?.message);

      return [];
    });

  return setDataArrAllWorks(dispatch, arrWorks ?? []);
};

export const addActivity = async (dispatch, form, privateSessionToken) => {
  let addActivity = "";

  const formData = new FormData();

  // formData.append("imgs[]", form.imgs);
  formData.append("place", form.place);
  formData.append("mail", form.mail);
  formData.append("phone", form.phone);
  formData.append("description", form.description);
  formData.append("title", form.title);
  // formData.append("create_usuario_id", form.id);

  const API = API_BASE;

  addActivity = await fetch(`${API}${PATH_ACTIVITIES}`, {
    method: "POST",
    body: formData,
    headers: {
      "x-token": TOKEN_DB,
      Authorization: `Bearer ${privateSessionToken}`,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      console.log(res);

      const saveImgs = async (img, reference) => {
        const formDataImg = new FormData();
        formDataImg.append("img", img);
        formDataImg.append("reference", reference);

        return await fetch(`${API}imgs`, {
          method: "POST",
          body: formDataImg,
          headers: {
            "x-token": TOKEN_DB,
            Authorization: `Bearer ${privateSessionToken}`,
            // "Content-Type": "multipart/form-data",
          },
        });
      };

      form.imgs.map((img) => {
        return saveImgs(img, res.id);
      });
      return Number(res.status) >= 200 && Number(res.status) <= 299
        ? "successful"
        : "failed";
    })
    .catch((error) => {
      console.error(error?.message);

      return "failed";
    });

  return addActivity;
};

export const getAllActivitiesInSuperAdmin = async (dispatch) => {
  let arrActivities = [];

  const API = API_BASE;

  arrActivities = await fetch(`${API}${PATH_GET_ALL_ACTIVITIES}`, {
    method: "GET",
    headers: {
      "x-token": TOKEN_DB,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error?.message);

      return [];
    });

  return setDataArrAllActivities(dispatch, arrActivities ?? []);
};
