/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// ---------------------------------------------------------
import "./ActivitiesScreen.css";
import Header from "../../components/header/Header";
import Activity from "../../components/activities/Activity";
// ---------------------------------------------------------

const ActivitiesScreen = ({ docTitle = "" }) => {
  const [activity, setWork] = useState({
    place: "",
    mail: "",
    phone: "",
    description: "",
    title: "",
    imgs: [],
  });

  let { search } = useLocation();
  let query = new URLSearchParams(search);
  const useQuery = (item) => query.get(item);
  let place = useQuery("place"),
    mail = useQuery("mail"),
    phone = useQuery("phone"),
    description = useQuery("description"),
    title = useQuery("title"),
    imgs = JSON.parse(useQuery("imgs"));

  useEffect(() => {
    setWork({
      ...activity,
      place,
      mail,
      phone,
      description,
      title,
      imgs,
    });
  }, []);

  return (
    <>
      {/* Class names starting with "wrs" refer to the file: WorkScreen.css */}
      <Helmet>
        <title>
          {docTitle} | {activity.title}
        </title>
      </Helmet>

      <Header />

      <div className="wrs_container">
        <Activity info={activity} isScreen />
      </div>
    </>
  );
};

export default ActivitiesScreen;
