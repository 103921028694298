/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
import { useEffect } from "react";
// ---------------------------------------------------------
import "./Home.css";
import Header from "../../components/header/Header";
import Banner from "../../components/banner/Banner";
import { t } from "../../languages/settingsLanguages";
import Button from "../../components/button/Button";
import { formatNumberWithDecimals } from "../../utils/Helpers";
import { getArrWorksHomeScreen } from "./Home.services";
import Work from "../../components/works/Work";
import { Link } from "react-router-dom";
import { WebContext } from "../../context/Context";
import Paragraph from "../../components/paragraph/Paragraph";
import assets from "../../assets/assets";
import Activity from "../../components/activities/Activity";
// ---------------------------------------------------------

const Home = ({ docTitle }) => {
  const { dispatch, arrAllWorks, arrAllActivities } = WebContext();

  useEffect(() => {
    getArrWorksHomeScreen(dispatch);
  }, [dispatch]);

  let comments = [
    {
      comment: `„Ich bin von der Idee und dem Service von CompanyU begeistert! Ein paar Optimierungen auf der Website wären von Vorteil, aber es wirkt sich definitiv positiv auf meine handwerkliche Karriere aus!“`,
      img: assets.imgs.Emilio,
      name: `Emilio`,
      age: `25`,
      profession: `Elektriker`,
      star: `☆☆☆☆`,
    },
    {
      comment: `„Ich habe von einem Arbeitskollegen von CompanyU erfahren. Mir ist bewusst, dass dieses Startup gerade erst beginnt, dennoch ist es für uns Handwerker sehr vielversprechend.“`,
      img: assets.imgs.Marius,
      name: `Marius`,
      age: `28`,
      profession: `Maurer`,
      star: `☆☆☆☆`,
    },
    {
      comment: `„Ich bleibe gespannt darauf, demnächst nicht nur lukrative Job-Angebote zu erhalten, sondern auch alles rund ums Handwerk hier zu finden.“`,
      img: assets.imgs.Guido,
      name: `Guido`,
      age: `39`,
      profession: `Anlagenmechaniker SHK`,
      star: `☆☆☆☆☆`,
    },
  ];

  return (
    <>
      {/* Class names starting with "hm" refer to the file: Home.css */}
      <Helmet>
        <title>{docTitle}</title>
      </Helmet>

      <Header />

      <div style={{ position: "relative" }}>
        <Banner
          otherClassNameContainer="hm_bannerWithSpline"
          img={
            "https://images.pexels.com/photos/17842843/pexels-photo-17842843/free-photo-of-mann-arbeiten-verbindung-draht.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          }
          alt="Banner Home"
          title={t("home-banner-title")}
          subtitle={t("home-banner-subtitle")}
          opacityDarkLayer={70}
        />

        <div className="hm_decoratioTop_container">
          <img
            className="hm_decoratioTop_img"
            src={assets.imgs.decoration}
            alt="Decoration"
          />
        </div>
      </div>

      <div className="hm_container">
        <section className="hm_info_container">
          <h1 className="hm_title">Dein Traumjob findet dich mit Company U</h1>

          <Paragraph otherClassNameParagraph="hm_info_txt">
            <p>
              Company U ist eine innovative Job-Plattform, die es Unternehmen
              ermöglicht, sich mit ihren besten Jobangeboten unkompliziert bei
              Handwerkern zu bewerben. Unsere Plattform ist für jeden offen -
              egal, ob erfahrener Handwerker, Quereinsteiger oder angehender
              Handwerker. Dank unserer fortschrittlichen Matching- Funktion
              entstehen ideale Verbindungen, ähnlich wie auf einer
              Dating-Plattform.
            </p>

            <p>
              Mit nur wenigen Klicks erhalten Nutzer die besten Job- und
              lukrativen Freizeitangebote aus ihrer Umgebung. Unser Service ist
              komplett kostenlos, schützt die Identität der Handwerker, ist
              unverbindlich und effizient, sodass bei uns jeder seinen Platz
              findet.
            </p>
          </Paragraph>
        </section>

        <section className="hm_increment_container">
          <div className="hm_increment_data_container">
            <h4>{formatNumberWithDecimals(130000)}+</h4>

            <p>offene Stellen Deutschlandweit</p>
          </div>

          <div className="hm_increment_data_container">
            <h4>{formatNumberWithDecimals(10000)}+</h4>

            <p>Unternehmen suchen dich</p>
          </div>
        </section>

        <h2 className="hm_companyu_title">COMPANY U</h2>

        <section className="hm_companyu_subtitle">
          <p>So einfach funktioniert es</p>

          <div className="hm_companyu_subtitle_icon_container">
            <img
              className="hm_companyu_subtitle_icon"
              src={assets.icons.iconNext}
              alt="Arrow"
            />
          </div>
        </section>

        <div className="hm_companyu_center">
          <div className="hm_companyu_container">
            <span className="hm_companyu_verticalLine" />

            <section className="hm_companyu_item_container">
              <div className="hm_companyu_item_num">
                <h3>1</h3>
              </div>

              <div className="hm_companyu_item_info">
                <h3>Registrierung</h3>

                <span className="hm_companyu_item_more_info">
                  <p>
                    Der Handwerker oder Quereinsteiger registriert sich mit
                    grundlegenden Angaben wie Name, Alter, Beruf, Geschlecht,
                    E-Mail-Adresse, Telefonnummer und Postleitzahl.
                  </p>

                  <Button
                    type="link"
                    target="_top"
                    link="/register-applicant"
                    title="Account erstellen"
                  />
                </span>
              </div>
            </section>

            <section className="hm_companyu_item_container">
              <div className="hm_companyu_item_num">
                <h3>2</h3>
              </div>

              <div className="hm_companyu_item_info">
                <h3>Sofortiger Nutzen</h3>

                <span className="hm_companyu_item_more_info">
                  <p>
                    Direkt nach der Registrierung kann der Handwerker passende
                    Jobs finden und hat Zugang zu lukrative Freizeitaktivitäten,
                    um einen sofortigen Bonus von Company U zu erhalten.
                  </p>

                  <Button
                    type="link"
                    target="_top"
                    link="/register-applicant"
                    title="Lukrative Freizeitaktivitäten Finden"
                  />
                </span>
              </div>
            </section>

            <section className="hm_companyu_item_container">
              <div className="hm_companyu_item_num">
                <h3>3</h3>
              </div>

              <div className="hm_companyu_item_info">
                <h3>Einfache Handhabung</h3>

                <span className="hm_companyu_item_more_info">
                  <p>
                    Die Hauptangaben sind schnell gemacht, was den
                    Registrierungsprozess vereinfacht.
                  </p>

                  <Button
                    type="link"
                    target="_top"
                    link="/register-applicant"
                    title="in 30 Sekunden fertigstellen"
                  />
                </span>
              </div>
            </section>

            <section className="hm_companyu_item_container">
              <div className="hm_companyu_item_num">
                <h3>4</h3>
              </div>

              <div className="hm_companyu_item_info">
                <h3>Informationen für maßgeschneiderte Angebote</h3>

                <span className="hm_companyu_item_more_info">
                  <p>
                    Für passendere Jobangebote kann der Handwerker weitere
                    Details wie Arbeits-Erfahrung, Fachbereich,
                    Gehaltsvorstellungen und eine Selbstbeschreibung in drei
                    Adjektiven hinzufügen.
                  </p>

                  <Button
                    type="link"
                    target="_top"
                    link="/register-applicant"
                    title="Passende Job-Angebote Erhalten"
                  />
                </span>
              </div>
            </section>
          </div>
        </div>

        <h2 className="hm_companyu_title">Company U Bonus</h2>

        <section className="hm_companyu_bonus_container">
          <div className="hm_companyu_bonus">
            <div className="hm_companyu_bonus_icon_container">
              <img
                className="hm_companyu_bonus_icon_icon"
                src={assets.icons.iconBonusLessInterviews}
                alt="Icon fewer interviews"
              />
            </div>

            <h2 className="hm_companyu_bonus_txt">
              Keine hunderten Bewerbungsgespräche mehr
            </h2>
          </div>

          <div className="hm_companyu_bonus">
            <div className="hm_companyu_bonus_icon_container">
              <img
                className="hm_companyu_bonus_icon_icon"
                src={assets.icons.iconBonusSaveTime}
                alt="Icon save time"
              />
            </div>

            <h2 className="hm_companyu_bonus_txt">Zeitersparnis</h2>
          </div>

          <div className="hm_companyu_bonus">
            <div className="hm_companyu_bonus_icon_container">
              <img
                className="hm_companyu_bonus_icon_icon"
                src={assets.icons.iconBonusLessStress}
                alt="Icon less stress"
              />
            </div>

            <h2 className="hm_companyu_bonus_txt">Weniger Stress</h2>
          </div>

          <div className="hm_companyu_bonus">
            <div className="hm_companyu_bonus_icon_container">
              <img
                className="hm_companyu_bonus_icon_icon"
                src={assets.icons.iconBonusMoreMoney}
                alt="Icon more money"
              />
            </div>

            <h2 className="hm_companyu_bonus_txt">
              Zugang zu den besten Bezahlungen, Konditionen und Zusatzleistungen
            </h2>
          </div>

          <div className="hm_companyu_bonus">
            <div className="hm_companyu_bonus_icon_container">
              <img
                className="hm_companyu_bonus_icon_icon"
                src={assets.icons.iconBonusWork}
                alt="Icon work"
              />
            </div>

            <h2 className="hm_companyu_bonus_txt">
              Beste Arbeitsatmosphäre in der Umgebung
            </h2>
          </div>

          <div className="hm_companyu_bonus">
            <div className="hm_companyu_bonus_icon_container">
              <img
                className="hm_companyu_bonus_icon_icon"
                src={assets.icons.iconBonusCool}
                alt="Icon cool"
              />
            </div>

            <h2 className="hm_companyu_bonus_txt">
              Komfortable Bewerbung mit nur 2 Minuten Aufwand
            </h2>
          </div>

          <div className="hm_companyu_bonus">
            <div className="hm_companyu_bonus_icon_container">
              <img
                className="hm_companyu_bonus_icon_icon"
                src={assets.icons.iconBonusActivity}
                alt="Icon activity"
              />
            </div>

            <h2 className="hm_companyu_bonus_txt">
              Lukrative Freizeitaktivitäten rund ums Handwerk
            </h2>
          </div>

          <h2 className="hm_companyu_title">Und das beste ist ?</h2>

          <div className="hm_companyu_bonus">
            <div className="hm_companyu_bonus_icon_container">
              <img
                className="hm_companyu_bonus_icon_icon"
                src={assets.icons.iconBonusFree}
                alt="Icon "
              />
            </div>

            <h2 className="hm_companyu_bonus_txt">
              Unser service ist Gratis, Anonym und Unverbindlich !
            </h2>
          </div>
        </section>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="link"
            target="_top"
            link="/register-applicant"
            title="Account erstellen"
          />
        </div>

        <div className="hm_horizontal_line" />

        <h3 className="hm_comments_section_title">
          Das sagen unsere Handwerkstalente über uns:
        </h3>

        <div className="hm_comments_container">
          <div className="hm_comments_center">
            {comments.map((comment, index) => {
              return (
                <section key={`comment_${index}`} className="hm_comment">
                  <p className="hm_comment_comment">{comment.comment}</p>

                  <div className="hm_comment_person_info_container">
                    <div className="hm_comment_img_container">
                      <img
                        className="hm_comment_img_img"
                        src={comment.img}
                        alt={comment.name}
                      />
                    </div>

                    <div className="hm_comment_person_data">
                      <p>
                        {comment.name}, {comment.age} Jahre alt
                      </p>

                      <p>
                        <i>{comment.profession}</i>
                      </p>
                    </div>
                  </div>

                  <div className="hm_comment_start_container">
                    <h3>{comment.star}</h3>
                  </div>
                </section>
              );
            })}
          </div>
        </div>

        <div className="hm_horizontal_line" />

        {arrAllWorks.length > 0 && (
          <>
            <h3 className="hm_works_title">Verfügbare Jobs</h3>

            <div className="hm_works_container">
              {arrAllWorks.map((work, index) => {
                return index < 3 && <Work key={`work_${index}`} work={work} />;
              })}
            </div>

            {arrAllWorks.length > 3 && (
              <nav className="hm_works_btn_container">
                <Link to={`/home-applicant`} className="hm_works_btn_more">
                  Mehr sehen
                </Link>
              </nav>
            )}

            <div className="hm_horizontal_line" />
          </>
        )}

        {arrAllActivities.length > 0 && (
          <>
            <h3 className="hm_works_title">Verfügbare Jobs</h3>

            <div className="hm_works_container">
              {arrAllWorks.map((info, index) => {
                return (
                  index < 3 && (
                    <Activity key={`activity_${index}`} info={info} />
                  )
                );
              })}
            </div>

            {arrAllWorks.length > 3 && (
              <nav className="hm_works_btn_container">
                <Link to={`/home-applicant`} className="hm_works_btn_more">
                  Mehr sehen
                </Link>
              </nav>
            )}

            <div className="hm_horizontal_line" />
          </>
        )}

        <div className="hm_ourPatner_container">
          <p>{t("home-our-patner")}</p>

          <img
            src={assets.imgs.ourPatner}
            alt="Our patner"
            className="hm_ourPatner_img"
          />

          <p className="hm_ourPatner_info">{t("home-patner-info")}</p>

          <Button
            type="link"
            link="https://web.arbeitsagentur.de/entgeltatlas/"
            target="_blank"
            otherClassNameBtn="hm_ourPatner_btn"
            title={t("applicants-banner-2-payment-atlas")}
          />

          <div className="hm_horizontal_line" />
        </div>

        <section className="hm_aboutUs_container">
          <div className="hm_aboutUs_bgimg_container">
            <img
              className="hm_aboutUs_bgimg_img"
              src="https://www.weser-kurier.de/resources/028a-1a3e088435fe-3391530830fa-1000/format/large/geschichte_von_joh_company_u.jpeg"
              alt="About Us: Company U"
            />

            <div className="hm_aboutUs_bgimg_darklayer" />
          </div>

          <div className="hm_aboutUs_info_container">
            <h3>About Us</h3>

            <nav className="hm_aboutUs_info_nav_container">
              <Link
                to={
                  "https://www.weser-kurier.de/landkreis-wesermarsch/gemeinde-lemwerder/digitale-loesung-gegen-fachkraeftemangel-start-up-vermittelt-handwerker-doc7u93k0j28hc46m7wsf"
                }
                target="_blank"
                className="hm_aboutUs_info_nav_link"
              >
                <div className="hm_aboutUs_info_nav_img_cont">
                  <img
                    className="hm_aboutUs_info_nav_img"
                    src={assets.imgs.newspaper1}
                    alt="About Us: Newspaper 1"
                  />
                </div>

                <div className="hm_aboutUs_info_nav_icon_cont">
                  <img
                    className="hm_aboutUs_info_nav_icon"
                    src={assets.icons.iconNext}
                    alt="About Us: Go newspaper"
                  />
                </div>
              </Link>

              <Link
                to={
                  "https://www.nwzonline.de/wesermarsch/start-up-in-lemwerder-drei-junge-maenner-wollen-handwerker-und-betriebe-zusammenbringen_a_4,0,3117690031.html"
                }
                target="_blank"
                className="hm_aboutUs_info_nav_link"
              >
                <div className="hm_aboutUs_info_nav_img_cont">
                  <img
                    className="hm_aboutUs_info_nav_img"
                    src={assets.imgs.newspaper2}
                    alt="About Us: Newspaper 2"
                  />
                </div>

                <div className="hm_aboutUs_info_nav_icon_cont">
                  <img
                    className="hm_aboutUs_info_nav_icon"
                    src={assets.icons.iconNext}
                    alt="About Us: Go newspaper"
                  />
                </div>
              </Link>
            </nav>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
