/**
 * AzPala
 * andrezpala@gmail.com
 * @flow
 */

import { useState } from "react";
// -----------------------------------------------------
import "./Input.css";
import { t } from "../../languages/settingsLanguages";
// -----------------------------------------------------

const Input = ({
  positionLabel = "top" || "right" || "bottom" || "left",
  otherClassNameDivContainer = "",
  divContainerFlexDirection = "",
  divContainerJustifyContent = "",
  divContainerAlignItems = "",
  id = `${Math.floor(Math.random() * 100) + 1}`,
  autofocus = false,
  onfocus = () => {},
  onblur = () => {},
  nameInput = "",
  otherClassNameInput = "",
  otherStylesInput = {},
  type = "text" ||
    "checkbox" ||
    "color" ||
    "date" ||
    "datetime-local" ||
    "email" ||
    "file" ||
    "hidden" ||
    "month" ||
    "number" ||
    "password" ||
    "range" ||
    "reset" ||
    "search" ||
    "tel" ||
    "time" ||
    "url" ||
    "week",
  placeholder = "Escribe...",
  defaultValue = "",
  min = undefined,
  max = undefined,
  minLength = undefined,
  maxLength = undefined,
  multiple = false,
  accept = type === "file" ? "image/*" : undefined,
  required = false,
  handleChange = (e) => {},
  handleBlurContainsError = (e) => {
    // It should return "true" if it contains at least one error or return "false" if it is error-free. Returning a non-boolean value will create an error.
    return false;
  },
  responseInput = (target, isOK) => {},
  otherClassNameBtnEye = "",
  otherStylesBtnEye = {},
  defaultEye = true,
  widthContainerEye = "10%",
  handleChangeStateEye = (currentEye) => {},
  childrenPersonalizedEye = <></>,
  label = false,
  otherClassNameLabel = "",
  otherStylesLabel = {},
  childrenLabel = <></>,
  errorMessage = "failed",
  dafaultErrorMessageContainer = true,
  otherClassNameDefaultContainerErrorMessage = "",
  otherStylesDefaultContainerErrorMessage = {},
  otherClassNameDefaultErrorMessage = "",
  otherStylesDefaultErrorMessage = {},
  childrenPersonalizedContainerErrorMessage = <></>,
}) => {
  const [err, setErr] = useState(false);
  // ---- If the "type" is "password" ----
  const [showingPassword, setShowingPassword] = useState(false);
  // ---- /If the "type" is "password" ----

  const onBlur = (e) => {
    onblur();

    const handleSetErr = (e, state) => {
      setErr(state);
      responseInput(e.target, !state);
    };

    if (typeof handleBlurContainsError(e) === "boolean") {
      if (handleBlurContainsError(e)) {
        // Error brought from the handleBlurContainsError function
        handleSetErr(e, true);

        /* 
        Regular expression for only numbers
        let regIsNum = /[0-9]/;
        */
      } else if (type === "email") {
        let regIsMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (!required) {
          e.target.value.length === 0
            ? handleSetErr(e, false)
            : handleSetErr(e, !regIsMail.test(e.target.value));
        } else {
          handleSetErr(e, !regIsMail.test(e.target.value));
        }
      } else if (required) {
        let isError =
          type === "checkbox" ? !e.target.checked : e.target.value.length === 0;

        // They are marked as an error if the user has not given an answer
        // setErr(isError);
        handleSetErr(e, isError);
      } else {
        // Error free
        handleSetErr(e, false);
      }
    } else {
      // The handleBlurContainsError function it should return "true" if it contains at least one error or return "false" if it is error-free. Returning a non-boolean value will create this error.
      throw new Error(
        "The handleBlurContainsError function must return a boolean (true or false)"
      );
    }

    return e;
  };

  let stylesPositionLabel = {
    top: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    right: {
      flexDirection: "row-reverse",
      justifyContent: "",
      alignItems: "",
    },
    bottom: {
      flexDirection: "column-reverse",
      justifyContent: "",
      alignItems: "",
    },
    left: {
      flexDirection: "row",
      justifyContent: "",
      alignItems: "",
    },
  };

  let flexDirection =
    divContainerFlexDirection !== ""
      ? divContainerFlexDirection
      : positionLabel === "top"
      ? stylesPositionLabel.top.flexDirection
      : positionLabel === "right"
      ? stylesPositionLabel.right.flexDirection
      : positionLabel === "bottom"
      ? stylesPositionLabel.bottom.flexDirection
      : positionLabel === "left"
      ? stylesPositionLabel.left.flexDirection
      : stylesPositionLabel.top.flexDirection;

  let justifyContent =
    divContainerJustifyContent !== ""
      ? divContainerJustifyContent
      : positionLabel === "top"
      ? stylesPositionLabel.top.justifyContent
      : positionLabel === "right"
      ? stylesPositionLabel.right.justifyContent
      : positionLabel === "bottom"
      ? stylesPositionLabel.bottom.justifyContent
      : positionLabel === "left"
      ? stylesPositionLabel.left.justifyContent
      : stylesPositionLabel.top.justifyContent;

  let alignItems =
    divContainerAlignItems !== ""
      ? divContainerAlignItems
      : positionLabel === "top"
      ? stylesPositionLabel.top.alignItems
      : positionLabel === "right"
      ? stylesPositionLabel.right.alignItems
      : positionLabel === "bottom"
      ? stylesPositionLabel.bottom.alignItems
      : positionLabel === "left"
      ? stylesPositionLabel.left.alignItems
      : stylesPositionLabel.top.alignItems;

  return (
    <>
      {/* Las iniciales inp_ de los estilos corresponden al archivo Input.css */}
      <div className={`inp_container ${otherClassNameDivContainer}`}>
        <div
          className={`inp_container`}
          style={{
            flexDirection,
            justifyContent,
            alignItems,
          }}
        >
          {label && (
            <label
              htmlFor={id}
              className={`inp_label ${otherClassNameLabel}`}
              style={otherStylesLabel}
            >
              {childrenLabel}
            </label>
          )}

          <div
            className={`inp_container_input ${
              type === "password" && "inp_input_password"
            }`}
          >
            <input
              id={id}
              autoFocus={autofocus}
              onFocus={() => onfocus()}
              name={nameInput}
              className={`inp_input ${otherClassNameInput}`}
              style={{
                ...otherStylesInput,
                width:
                  type === "password"
                    ? `calc(100% - ${widthContainerEye} - 4px)`
                    : `calc(100% - 10px)`,
                paddingRight:
                  type === "password" ? `calc(${widthContainerEye} - 5px)` : 1,
              }}
              type={
                type !== "password"
                  ? type
                  : showingPassword
                  ? "text"
                  : "password"
              }
              placeholder={placeholder}
              max={max}
              min={min}
              minLength={minLength}
              maxLength={maxLength}
              accept={accept}
              defaultValue={defaultValue}
              required={required}
              onChange={(e) => {
                handleChange(e);
                responseInput(e.target, !err);
              }}
              onBlur={(e) => onBlur(e)}
              multiple={multiple}
            />

            {type === "password" && (
              <button
                className={`inp_eye_btn ${otherClassNameBtnEye}`}
                style={{ ...otherStylesBtnEye, width: widthContainerEye }}
                onClick={(e) => {
                  e.preventDefault();

                  setShowingPassword(!showingPassword);
                  handleChangeStateEye(!showingPassword);
                }}
              >
                {defaultEye ? (
                  <>
                    {showingPassword ? (
                      <img
                        src={require("./assets/eye-hide.png")}
                        alt="Hidden"
                        className="inp_eye_img"
                      />
                    ) : (
                      <img
                        src={require("./assets/eye-see.png")}
                        alt="Show"
                        className="inp_eye_img"
                      />
                    )}
                  </>
                ) : (
                  <>{childrenPersonalizedEye}</>
                )}
              </button>
            )}
          </div>
        </div>

        {dafaultErrorMessageContainer ? (
          <div
            className={`inp_msmError_container ${otherClassNameDefaultContainerErrorMessage}`}
            style={otherStylesDefaultContainerErrorMessage}
          >
            {err && (
              <small
                className={`inp_msmError_small ${otherClassNameDefaultErrorMessage}`}
                style={otherStylesDefaultErrorMessage}
              >
                {errorMessage}
              </small>
            )}
          </div>
        ) : (
          <>{childrenPersonalizedContainerErrorMessage}</>
        )}
      </div>
    </>
  );
};

export default Input;

export const ChildrenLabelDefault = ({
  txt = "",
  otherClassNameP = "",
  color = "#000000",
  otherClassNameReq = "",
  colorReq = "#ff0000",
  required = false,
  defaultReq = true,
  childrenPersonalizedReq = <></>,
}) => {
  return (
    <p className={`inp_childrenLabel ${otherClassNameP}`} style={{ color }}>
      {txt}{" "}
      {required && (
        <small className={`${otherClassNameReq}`} style={{ color: colorReq }}>
          {defaultReq ? "*" : <>{childrenPersonalizedReq}</>}
        </small>
      )}
    </p>
  );
};

export const Options = ({
  otherClassNameOption = "",
  label = "",
  arr = [{ name: "Name", value: "name", icon: "" }],
  onclick = (value) => {},
  select = "",
  arrSelects = [
    /* { value: "" } */
  ],
  required = false,
  error = false,
  errorMessage = "",
}) => {
  const handleOnClickOption = (e, value) => {
    e.preventDefault();

    onclick(value);
  };

  return (
    <div className="inp_options_info_container">
      {label !== "" && <ChildrenLabelDefault required={required} txt={label} />}

      <section className="inp_options_container">
        {arr.map((item, index) => {
          let _equals =
            arrSelects.length > 0 &&
            arrSelects.map((option) =>
              option.value === item.value && option.value !== ""
                ? option.value
                : null
            );
          let _equal =
            arrSelects.length > 0 &&
            _equals.findIndex((option) => option === item.value);
          let equal = arrSelects[_equal];

          return (
            <button
              key={`option_${item.value}_${index}`}
              className={`inp_option_btn ${otherClassNameOption} ${
                select === item.value || equal ? "inp_option_btn_selected" : ""
              }`}
              onClick={(e) => handleOnClickOption(e, item.value)}
            >
              {item.icon !== "" && item.icon !== undefined ? (
                <div className="inp_options_icon_container">
                  <img
                    className="inp_options_icon_icon"
                    src={item.icon}
                    alt={`Icon ${item.name}`}
                  />
                </div>
              ) : null}
              <p>{t(item.name)}</p>
            </button>
          );
        })}
      </section>

      {error && (
        <div className="inp_error_msm_container">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export const Selector = ({
  id = "",
  name = id,
  label = "",
  arr = [{ name: "Name", value: "name" }],
  onclick = (value) => {},
  select = "",
  required = false,
  error = false,
  errorMessage = "",
}) => {
  const handleOnClickSelect = (e, value) => {
    e.preventDefault();

    onclick(value, e);
  };

  return (
    <div className="inp_options_info_container">
      {label !== "" && (
        <label htmlFor={name}>
          <ChildrenLabelDefault required={required} txt={label} />
        </label>
      )}

      <select
        id={name}
        className="inp_options_container"
        onChange={(e) => handleOnClickSelect(e, e.target.value)}
        defaultValue={select}
      >
        {arr.map((item, index) => {
          return (
            <option
              key={`selector_${item.value}_${index}`}
              className={`inp_option_btn ${
                select === item.value && "inp_option_btn_selected"
              }`}
              value={item.value}
            >
              {t(item.name)}
            </option>
          );
        })}
      </select>

      {error && (
        <div className="inp_error_msm_container">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export const TextArea = ({
  id = "",
  name = id,
  label = "",
  colorLabel = "initial",
  placeholder = "",
  defaultValue = "",
  responseTextArea = (target, isOK) => {},
  required,
  error = false,
  errorMessage,
}) => {
  const handleChangeTextArea = (e) => {
    return responseTextArea(
      e.target,
      required ? e.target.value.length > 0 : true
    );
  };

  return (
    <div className="inp_textarea_container">
      {label !== "" && (
        <label htmlFor={name}>
          <ChildrenLabelDefault
            required={required}
            txt={label}
            color={colorLabel}
          />
        </label>
      )}

      <textarea
        id={id}
        name={name}
        className="inp_textarea"
        placeholder={placeholder}
        onChange={(e) => handleChangeTextArea(e)}
        onBlur={(e) => handleChangeTextArea(e)}
        defaultValue={defaultValue}
      ></textarea>

      {error && (
        <div className="inp_error_msm_container">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};
