/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
// ---------------------------------------------------------
import "./SuperAdmin.css";
import { t } from "../../languages/settingsLanguages";
import { addActivity } from "./servicesSuperAdmin";
import { WebContext } from "../../context/Context";
import Input, {
  ChildrenLabelDefault,
  TextArea,
} from "../../components/input/Input";
import Button from "../../components/button/Button";
import { userSuperAdmin } from "../../utils/Helpers";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const AddActivitiesSuperAdmin = () => {
  const { dispatch } = WebContext();
  const { token, id } = userSuperAdmin.get();

  const initialForm = {
    imgs: [],
    place: "" /* Required */,
    mail: "" /* Required */,
    phone: "" /* Required */,
    description: "" /* Required */,
    title: "" /* Required */,
  };

  const [newWorkData, setNewWorkData] = useState(initialForm);
  const [arrImgs, setArrImgs] = useState([]);

  const [errorFree, setErrorFree] = useState({
    ...newWorkData,
    allErrorFree: false,
  });

  const [sending, setSending] = useState(false);
  const [statusSave, setStatusSave] = useState();

  const handleChangeInput = (target, isOK) => {
    setNewWorkData({
      ...newWorkData,
      [target.name]: target.value,
    });

    setErrorFree({
      ...errorFree,
      [target.name]: isOK,
    });
  };

  // Función para manejar la selección de imágenes
  // const handleImageChange = (event) => {
  //   setArrImgs(event.target.files);
  // };
  const handleImageChange = (event) => {
    const files = event.target.files;
    const imagesArray = Array.from(files).map(
      (file) =>
        // URL.createObjectURL(file)
        file
    );
    setArrImgs((prevImages) => [...prevImages, ...imagesArray]);
  };

  const sendSuccessful = (res) => {
    setNewWorkData(initialForm);
    setStatusSave(res);
  };

  const handleSubmit = async () => {
    setSending(true);

    addActivity(dispatch, newWorkData, token).then((res) => {
      setSending(false);
      return res === "failed" ? setStatusSave(res) : sendSuccessful(res);
    });

    return;
  };

  useEffect(() => {
    setNewWorkData({ ...newWorkData, id });
  }, [id]);

  let regIsPhone = /^\+?\d{10,13}$/;
  let regIsMail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

  useEffect(() => {
    if (
      errorFree.place &&
      errorFree.mail &&
      errorFree.phone &&
      errorFree.description &&
      errorFree.title &&
      !errorFree.allErrorFree
    ) {
      return setErrorFree({
        ...errorFree,
        allErrorFree: true,
      });
    } else if (
      (!errorFree.place ||
        !errorFree.mail ||
        !errorFree.phone ||
        !errorFree.description ||
        !errorFree.title) &&
      errorFree.allErrorFree
    ) {
      return setErrorFree({
        ...errorFree,
        allErrorFree: false,
      });
    } else return;
  }, [errorFree]);

  useEffect(() => {
    console.log(arrImgs);
    setNewWorkData({
      ...newWorkData,
      imgs: arrImgs,
      prevImgs: Object.values(arrImgs),
    });
  }, [arrImgs]);

  return (
    <>
      {/* Class names starting with "super" refer to the file: SuperAdmin.css */}

      <section className="super_newWork_container">
        <h2>Neue Beschäftigung</h2>

        {/* -------------------- ProfileFile -------------------- */}
        <div className="ffa_form_changeProfile">
          {newWorkData.imgs.length !== 0 ? (
            <div className="ffa_profileFile_container">
              <button
                className="ffa_profileFile_btn"
                onClick={() =>
                  setNewWorkData({
                    ...newWorkData,
                    imgs: [],
                  })
                }
              >
                {typeof newWorkData.imgs === "object" ? (
                  <>
                    {newWorkData.prevImgs.map((image, index) => (
                      <img
                        key={`newWorkData-img-${index}`}
                        src={URL.createObjectURL(image)}
                        alt={`Imagen ${index}`}
                        style={{
                          width: 50,
                          height: 50,
                          objectFit: "cover",
                          margin: 5,
                        }}
                      />
                    ))}
                  </>
                ) : (
                  <img
                    className="ffa_profileFile_img"
                    src={assets.brand.logoColorBgTransparent}
                    alt="Profile File"
                  />
                )}

                <p>{t("form-choose-other-image")}</p>
              </button>
            </div>
          ) : (
            <Input
              type="file"
              id="imgs"
              nameInput="imgs"
              handleChange={(e) => handleImageChange(e)}
              label
              multiple
              childrenLabel={
                <ChildrenLabelDefault
                  txt={t("form-applicant-step-1-profile-file")}
                />
              }
            />
          )}

          <div className="ffa_form_change_container">
            <Button
              title="Stornieren"
              otherClassNameBtn="ffa_form_change_imgProfile_cancel"
              onclick={() => setArrImgs([assets.brand.logoColorBgTransparent])}
            />

            {/* <Button
              title="Halten"
              otherClassNameBtn={
                newWorkData.imgs.length === 0 ||
                newWorkData.imgs === assets.brand.logoColorBgTransparent
                  ? "ffa_form_change_imgProfile_save_disabled"
                  : false
              }
              onclick={(e) => {
                e.preventDefault();
              }}
            /> */}
          </div>
        </div>
        {/* -------------------- /ProfileFile ------------------------- */}
        {/* -------------------- Place -------------------- */}
        <Input
          id="place"
          nameInput="place"
          placeholder="Ort"
          defaultValue={newWorkData.place}
          required
          responseInput={(target, isOK) => handleChangeInput(target, isOK)}
          label
          childrenLabel={<ChildrenLabelDefault required txt={"Ort"} />}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Place -------------------- */}

        {/* -------------------- Email -------------------- */}
        <Input
          id="mail"
          nameInput="mail"
          placeholder="yourmail@mail.com"
          defaultValue={newWorkData.mail}
          type="email"
          required
          handleBlurContainsError={(e) => {
            return (
              e.target.value.length < 6 || !regIsMail.test(newWorkData.mail)
            );
          }}
          responseInput={(target, isOK) => handleChangeInput(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault required txt={"E-Mail Adresse"} />
          }
          errorMessage={
            newWorkData.mail.length === 0
              ? t("form-required")
              : !regIsMail.test(newWorkData.mail)
              ? t("form-applicant-step-1-please-valid-mail")
              : ""
          }
        />
        {/* -------------------- /Email -------------------- */}

        {/* -------------------- Phone number -------------------- */}
        <Input
          id="phone"
          nameInput="phone"
          placeholder="0000000000"
          defaultValue={newWorkData.phone}
          type="tel"
          required
          handleBlurContainsError={(e) => {
            let validNumberPhone = !regIsPhone.test(e.target.value);

            return validNumberPhone;
          }}
          responseInput={(target, isOK) => handleChangeInput(target, isOK)}
          label
          childrenLabel={
            <ChildrenLabelDefault required txt={"Telefonnummer"} />
          }
          errorMessage={
            newWorkData.phone.length === 0
              ? t("form-required")
              : !regIsPhone.test(newWorkData.phone)
              ? t("form-applicant-step-1-please-valid-number")
              : ""
          }
        />
        {/* -------------------- /Phone number -------------------- */}

        {/* -------------------- Work Description -------------------- */}
        <TextArea
          id="description"
          label={"Descripción"}
          placeholder={"Descripción"}
          responseTextArea={(target, isOK) => {
            handleChangeInput(target, isOK);
          }}
          defaultValue={newWorkData.description}
          error={!!!errorFree.description}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Work Description -------------------- */}

        {/* -------------------- Title -------------------- */}
        <TextArea
          id="title"
          label={"Título"}
          placeholder={"Título"}
          responseTextArea={(target, isOK) => {
            handleChangeInput(target, isOK);
          }}
          defaultValue={newWorkData.title}
          error={!!!errorFree.title}
          errorMessage={t("form-required")}
        />
        {/* -------------------- /Title -------------------- */}

        <div className="super_send_container">
          {statusSave === "failed" ? (
            <>
              <div>
                <h4>Failed</h4>
              </div>
            </>
          ) : statusSave === "successful" ? (
            <>
              <div>
                <h4>Erfolgreich gesendet</h4>

                <Button
                  title={"Crear nuevo"}
                  onclick={() => window.location.reload()}
                />
              </div>
            </>
          ) : (
            <>
              {errorFree.allErrorFree ? (
                <Button
                  title={sending ? "Loading..." : "Job hinzufügen"}
                  onclick={() => !sending && handleSubmit()}
                />
              ) : (
                <div>
                  <h4>Absenden (alle Felder ausfüllen)</h4>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default AddActivitiesSuperAdmin;
