/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import {
  API_BASE,
  PATH_LOGIN_COMPANY,
  PATH_SUBSCRITION,
  TOKEN_DB,
} from "../../api/apis";
import { setUsercompany } from "../../context/Actions";
import {
  userApplicant,
  userInfluencer,
  yourPackage,
} from "../../utils/Helpers";
// ---------------------------------------------------------

export const getSubscription = async (dispatch, id, token) => {
  const API = API_BASE;

  await fetch(`${API}${PATH_SUBSCRITION}/${id}`, {
    method: "GET",
    headers: {
      "x-token": TOKEN_DB,
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      yourPackage.set(dispatch, res);

      return yourPackage.get();
    })
    .catch((error) => {
      // console.error(error);
    });
};

const servicesLoginCompany = async (
  dispatch,
  credentials,
  response = (response = false) => {},
  loading = (state = false) => {}
) => {
  loading(true);
  const API = API_BASE;
  let _credentials = {
    mail: credentials.login_mail,
    password: credentials.login_password,
  };

  await fetch(`${API}${PATH_LOGIN_COMPANY}`, {
    method: "POST",
    headers: {
      "x-token": TOKEN_DB,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(_credentials),
  })
    .then((res) => res.json())
    .then(async (res) => {
      
      let usercompany = {
        id: res.id,
        name: res.name,
        description: res.description,
        link: res.link,
        contact_name: res.contact_name,
        contact_email: res.contact_email,
        contact_phone: res.contact_phone,
        offers: res.offers,
        token: res.token,
      };

      setUsercompany(dispatch, usercompany);
      localStorage.setItem("usercompany", JSON.stringify(usercompany));
      response(
        res.message === "failed credentails"
          ? "failed-credentials"
          : "successful"
      );
      loading(false);

      userInfluencer.clear();
      userApplicant.clear();
    })
    .catch((error) => {
      response("failed");
      loading(false);
    });
};

export default servicesLoginCompany;
