/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./HomeApplicant.css";
import { t } from "../../languages/settingsLanguages";
import HeaderInAdmin from "../../components/header/HeaderInAdmin";
import { WebContext } from "../../context/Context";
import servicesHomeApplicant, { getAllWorks } from "./servicesHomeApplicant";
import { userApplicant } from "../../utils/Helpers";
import Button from "../../components/button/Button";
import { useEffect, useState } from "react";
import Work from "../../components/works/Work";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const HomeApplicant = ({ docTitle }) => {
  const { dispatch, arrAllWorks } = WebContext();
  const { perfil, name, lastname, city, birthdate, tel, id, token } =
    userApplicant.get();

  const navigate = useNavigate();

  useEffect(() => {
    if (token === "" || token === undefined) {
      navigate("/login-applicant");
    }
  });

  useEffect(() => {
    getAllWorks(dispatch);
  }, [dispatch, token]);

  return (
    <>
      {/* Class names starting with "hma" refer to the file: HomeApplicant.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-home-applicant")}
        </title>
      </Helmet>

      <HeaderInAdmin typeAdmin="applicant" />

      <div className="hma_container">
        <div className="hma_welcome">
          <h2>Hallo, {name}</h2>
        </div>

        <div className="hma_dashboard_container">
          <section className="hma_card_container">
            <h3>Aktualisieren Sie meine Daten</h3>

            <div className="hm_card_profile_container">
              <img
                src={
                  perfil === null
                    ? assets.icons.prewProfileImg
                    : `https://companyu.de/backend/${perfil}`
                }
                alt={`Profile ${name}`}
                className="hm_card_profile_img"
              />
            </div>

            <ul className="hma_card_ul">
              <li>{name}</li>
              <li>{lastname}</li>
              {/* <li>{birthdate}</li>
              <li>{tel}</li>
              <li>{city}</li> */}
            </ul>

            {/* <div className="hma_btns_container">
              <Button
                type="link"
                link="/update-applicant"
                title="Daten aktualisieren"
              />
            </div> */}
          </section>

          <section className="hma_works_container">
            <h2 className="hma_works_title">Freizeit Beschäftigung</h2>

            <div className="hma_works_arr_container">
              {arrAllWorks.length === 0 ? (
                <>
                  <p>No hay ofertas aún</p>
                </>
              ) : (
                <>
                  {arrAllWorks.map((work, index) => {
                    return <Work key={`work_${index}`} work={work} />;
                  })}
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default HomeApplicant;
