/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
// ---------------------------------------------------------

const Reducers = (state, action) => {
  switch (action.type) {
    case "DEFAULT_TEST":
      return {
        ...state,
        defaultTest: action.payload,
      };

    case "CHANGE_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };

    case "SET_ARR_APPLICANTS_PREVIEW":
      return {
        ...state,
        arrApplicantsPreview: action.payload,
      };

    case "SET_LOADING_ARR_APPLICANTS_PREVIEW":
      return {
        ...state,
        loadingArrApplicantsPreview: action.payload,
      };

    case "SET_ARR_APPLICANTS_WITH_FILTERS":
      return {
        ...state,
        arrApplicantsWithFilters: action.payload,
      };

    case "SET_LOADING_ARR_APPLICANTS_WITH_FILTERS":
      return {
        ...state,
        loadingArrApplicantsWithFilters: action.payload,
      };

    case "USER_SUPERADMIN":
      return {
        ...state,
        usersuperadmin: action.payload,
      };

    case "USER_COMPANY":
      return {
        ...state,
        usercompany: action.payload,
      };

    case "USER_INFLUENCER":
      return {
        ...state,
        userinfluencer: action.payload,
      };

    case "USER_APPLICANT":
      return {
        ...state,
        userapplicant: action.payload,
      };

    case "SAVE_APPLICANT_ID":
      return {
        ...state,
        saveApplicantID: action.payload,
      };

    case "YOUR_PACKAGE":
      return {
        ...state,
        yourpackage: action.payload,
      };

    case "LAST_VISITED_ROUTE":
      return {
        ...state,
        lastVisitedRoute: action.payload,
      };

    case "ARR_ALL_WORKS":
      return {
        ...state,
        arrAllWorks: action.payload,
      };

    case "ARR_ALL_ACTIVITIES":
      return {
        ...state,
        arrAllActivities: action.payload,
      };

    default:
      return state;
  }
};

export default Reducers;
