/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect } from "react";
// ---------------------------------------------------------
import "./SuperAdmin.css";
import { getAllActivitiesInSuperAdmin } from "./servicesSuperAdmin";
import { WebContext } from "../../context/Context";
import Activity from "../../components/activities/Activity";
// ---------------------------------------------------------

const ViewAllActivitiesSuperAdmin = () => {
  const { dispatch, arrAllActivities } = WebContext();

  useEffect(() => {
    getAllActivitiesInSuperAdmin(dispatch);
  }, [dispatch]);

  return (
    <>
      {/* Class names starting with "super" refer to the file: SuperAdmin.css */}

      <section className="hma_works_container">
        <h2 className="hma_works_title">Freizeit Beschäftigung</h2>

        <div className="hma_works_arr_container">
          {arrAllActivities.length === 0 ? (
            <>
              <p>Keine Jobs verfügbar</p>
            </>
          ) : (
            <>
              {arrAllActivities.map((activity, index) => {
                return <Activity key={`activity_${index}`} info={activity} />;
              })}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default ViewAllActivitiesSuperAdmin;
